import { React, useState } from "react";
import { isMobile } from "web3modal";
import "../CSS/ExpandText.css";

export default function ExpandText(props) {
  const [hovered, setHover] = useState(false);
  return (
    <div class="container-fluid text-start">
      <div class="row">
        <div
          className="btn TextExpand text-start titleText"
          onMouseEnter={onMouseEnterHandler}
          onClick={onClickHandler}
          onMouseLeave={onMouseLeaveHandler}
        >
          {props.title}
        </div>
      </div>
      <div class="row text-start position-static descriptionText">
        <span className={"expandtext mt-3 " + (hovered ? "fadeText" : "faded")}>
          {props.description}
        </span>
      </div>
    </div>
  );

  function onClickHandler() {
    if (window.innerWidth < 992) {
      setHover(!hovered);
    }
  }

  function onMouseEnterHandler() {
    if (window.innerWidth > 992) {
      setHover(true);
    }
  }
  function onMouseLeaveHandler() {}
}
