import { React, useState } from "react";
import MintButton from "./MintButton";
import "../CSS/MainCSS.css";
import ExpandText from "./ExpandText";
import AudioVisual from "./AudioVisual";
import ReactModal from "react-modal";
import { isMobile, isHQ } from "../Utils/Resolution";

export default function Main() {
  let expands = [
    {
      title: (
        <>
          <p>WHAT IS THE</p>
          <p>BACKSTAGE PASS?</p>
        </>
      ),
      description: (
        <>
          <p>
            The Tune Crüe Backstage Pass is a priority pass that grants holders exclusive access to all IRL/Virtual Tune Crüe events,  free mint on future collections, and alpha channels for Music related topics as well as Crypto/NFTs signals.
            {/* The backstage pass provides valueable benefits pre and post mint. It
            is our way of saying „thank you“ to those, who believed and invested
            in us and our vision early. */}
          </p>
          {/* <p>The backstage pass is a 1/1444 animatedNFT priced at 0.085 ETH.</p> */}
        </>
      ),
    },
    {
      title: (
        <>
          <p>WHAT ARE THE ADVANTAGES THAT </p>
          <p>COME WITH OWNING IT ?</p>
        </>
      ),
      description: (
        <>
          <p>Holders will also have the following advantages on our upcoming launch:</p>
          <p className="mb-0">- A discounted overall mint price.</p>
          <p className="mb-0">- Free mint of a TuneScape NFT, a customizable “banner” style NFT.</p>
          <p className="mb-0">- The ability to mint a second full set  during the pre-sale.</p>
          <p className="mb-0">- Added staking benefits.</p>
          <p className="mb-0">- An entry into a raffle for 2 complete Psychedelics Anonymous sets. Each pass is one entry, the raffle will be pulled at sellout.</p>
          {/* <p>
            - A second presale for where holders will be able to purchase one
            more set of NFTs out of the remaining supply before public sale
          </p>
          <p className="mb-0">post mint:</p>
          <p className="mb-0">
            - Access to an alpha chat with experts of both, the NFT/ crypto and
            music industry
          </p>
          <p className="mb-0">- Acts as priority pass for future collections</p>
          <p>- Extra staking benefits </p> */}
        </>
      ),
    },
  ];

  let [showModal, setShowModal] = useState(false);
  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 order-4 order-xl-1">
          <div class="row mt-5">
            <div class="col-xl-6 col-12">
              <div class="row">
                <div class="col-1 d-xl-block d-none"></div>
                <div class="col-xl-8 col-12">
                  <ExpandText
                    title={expands[0].title}
                    description={expands[0].description}
                  />
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-12">
              <div class="row mt-5"></div>
              <div class="row">
                <div class="col-2 d-xl-block d-none"></div>
                <div class="col-xl-7 col-12">
                  <ExpandText
                    title={expands[1].title}
                    description={expands[1].description}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 d-block d-xl-none my-5 order-0"></div>
        <div class="col-12 d-block d-xl-none my-5 order-0"></div>
        <div class="col-12 order-1 order-xl-4 mainDiv">
          <div class="row">
            <div class="col-lg-8 col-12 mx-auto">
              <img
                style={{ width: "100%" }}
                src={require("../Images/title.png")}
              ></img>
            </div>
          </div>
          <div class="row mt-4">
            <MintButton />
          </div>
          <div class={isHQ ? "row mt-5" : "d-none"}></div>
          <div class="row mt-4">{<AudioVisual />}</div>
          <div class={isHQ ? "row mt-5" : "d-none"}></div>
        </div>
        <div class="col-12 order-5 order-xl-5 logoImageDiv">
          <div class="row">
            <div class="col-12 mt-3 mx-auto text-center">
              <img className="logoImage" src={require("../Images/logo.png")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
