import { React, useState, useRef } from "react";
import AudioSpectrum from "react-audio-spectrum";
import BGMusicFile from "../Audio/soundmix.mp3";
import "../CSS/AudioVisual.css";
import { isSafari } from "react-device-detect";
import { isHQ, isMobile } from "../Utils/Resolution";

export default function AudioVisual() {
  const [SoundOn, setSound] = useState(false);
  const [volume, setVolume] = useState(100);
  document.getElementById("audio").volume = volume / 100;

  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-4 mx-auto">
            <button
              id="play"
              className="btn btn-transparent p-0 m-0 playButtonAudio"
              style={{ borderRadius: 100 }}
              onClick={() => {
                togglePlay();
              }}
            >
              {document.getElementById("audio").paused ? (
                <img
                  className="playButtonImage"
                  src={require("../Images/playicon.png")}
                />
              ) : (
                <img
                  className="playButtonImage"
                  src={require("../Images/pauseicon.png")}
                />
              )}
            </button>
          </div>
        </div>
        <div>
          <div class={isHQ ? "row my-5" : "d-none"}></div>
          <div class="row mt-4 d-none d-lg-block">
            <div class="col-lg-2 col-10 mx-auto">
              <input
                className="volume"
                type="range"
                min={0}
                max={100}
                step={1}
                value={volume}
                id="volume-control"
                onChange={(event) => {
                  setVolume(event.target.valueAsNumber);
                }}
              />
            </div>
          </div>
          <div class="row d-none d-lg-block">
            <div class="col-6 col-lg-6 text-center mx-auto volText">Volume</div>
          </div>
        </div>
      </div>
      <div
        className="audio sticky-bottom"
        style={{
          zIndex: -100,
        }}
      ></div>
    </>
  );

  function togglePlay() {
    setSound(!SoundOn);
  }
}
