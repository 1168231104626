import { useState, React, useEffect, useCallback } from "react";
import { ethers } from "ethers";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import "../CSS/MintButton.css";
import Web3 from "web3";
import ReactModal from "react-modal";
import ReactLoading from "react-loading";

// TODO change Chaing ID before releasing

const CONT_ADD = "0x82e45fc1fadf19765cd934f24cb52aa1cba0f5a4";
const wlVerify = 1;
const contractChainID = 1;
const debug = true;
let voucher = null;
let accountListing = null;
const ABI = require("../JSON/TuneCrueBP.json").abi;
// const WHITELIST = require("../JSON/whitelist.json");
const infuraID =
  "https://mainnet.infura.io/v3/b5642f739f024877b3da12afc514b774";

const REACT_APP_API_SECRET = "d221d3ae-c444-4b03-ae19-ea58b30bf571";
const backendURI = "https://tunecrue-backend.vercel.app/api/voucher"

export const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "Web 3 Modal Demo",
      infuraId: infuraID,
    },
  },
  walletconnect: {
    package: WalletConnect,
    options: {
      infuraId: infuraID,
    },
  },
};

const web3Modal = new Web3Modal({
  providerOptions, // required
});

export default function MintButton() {
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [network, setNetwork] = useState();
  const [chainId, setChainId] = useState();
  const [isCorrectChain, setCorrectChain] = useState();
  const [WhitelistStat, setWhiteListStat] = useState(null);
  const [MintCountState, setMintCountState] = useState(null);
  const [isPresale, setIsPresale] = useState(null);
  const [isPaused, setIsPaused] = useState(null);
  const [isSoldOut, setIsSoldOut] = useState(null);
  const [canFreemint, setCanFreemint] = useState(null);
  const [maxSupply, setMaxSupply] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);

  const [isMinting, setIsMinting] = useState(false);
  const [hasFunds, sethasFunds] = useState(null); 

  const disconnect = useCallback(async () => {
    await web3Modal.clearCachedProvider();
    refreshState();
    window.location.reload(false);
  });

  useEffect(() => {
    getMaxSupply();
    getTotalSupply();
    if (provider?.on) {
      const handleAccountsChanged = async (accounts) => {
        if(debug){
          console.log("accountchanged 1");
        }
        if (accounts) {
          let isFreemint = false
          let uri = `${backendURI}/${accounts[0]}/freemint/${REACT_APP_API_SECRET}`
          let response = await fetch(uri);
          let jsonReply = await response.json();
          if(debug){
            console.log("No Freemint")
          }
          if(jsonReply != "NotWhitelisted"){ // has a freemint
            if(debug){
              console.log("hasFreemint")
            }
            const responseArray = jsonReply.split(',');
            voucher = responseArray[0];
            accountListing = responseArray[1];

            const didReach = await reachedMaxMints(provider, accounts[0], true); // check if used
            setMintCountState(didReach ? 0 : 1);
            if(!didReach){ // free mint not used
              if(debug){
                console.log("freemint not used")
              }
              setWhiteListStat(1)
              isFreemint = true
            } else {
              if(debug){
                console.log("Freemint used")
              }
            }
          }

          if(!isFreemint){ 
            if(isPresale){
              const uri = `${backendURI}/${accounts[0]}/presales/${REACT_APP_API_SECRET}`
              const response = await fetch(uri);
              const jsonReply = await response.json();
              if(jsonReply != "NotWhitelisted"){
                const responseArray = jsonReply.split(',');
                voucher = responseArray[0];
                accountListing = responseArray[1];
                setMintCountState(null);
                if(accountListing === "freemint") { 
                  const didReach = await reachedMaxMints(provider, accounts[0], true);
                  setMintCountState(didReach ? 0 : 1);
                } else {
                  const didReach = await reachedMaxMints(provider, accounts[0], false);
                  setMintCountState(didReach ? 0 : 1);
                }
                setWhiteListStat(1)
              } else {
                setMintCountState(null);
                const didReach = await reachedMaxMints(provider, accounts[0], false);
                setMintCountState(didReach ? 0 : 1);
                setWhiteListStat(0);
                accountListing = "presale";
                voucher = null;
              }
            } else {
              accountListing = "public"
              setMintCountState(null);
              const didReach = await reachedMaxMints(provider, accounts[0], false);
              setMintCountState(didReach ? 0 : 1);
            }
          }

          if(debug){
            console.log(`accountListing ${accountListing}`)
          }
          const hasEnoughfunds = await checkFunds(provider, accounts[0]);
          if(debug){
            console.log(`hasEnoughfunds ${hasEnoughfunds}`)
          }
          sethasFunds(hasEnoughfunds ? 1 :0);

          setAccount(accounts[0]);
        }
      };

      const handleChainChanged = (_hexChainId) => {
        if(debug){
          console.log("chainChanged");
        }
        if (_hexChainId !== chainId) {
          // setCorrectChain(_hexChainId === 4);
          setCorrectChain(_hexChainId === contractChainID);
          setChainId(_hexChainId);
        }
      };

      const handleDisconnect = () => {
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [disconnect, provider]);

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork("");
  };

  const switchNetwork = async () => {
    try {
      const _hexChainId = "0x" + contractChainID.toString(16);
      if (debug) {
        console.log(_hexChainId)
      }
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: _hexChainId }],
      });
      window.location.reload();
    } catch (switchError) {
      if(debug){
        console.log("error " + switchError);
      }
    }
  };

  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      setProvider(provider);
      setLibrary(library);
      if (accounts) setAccount(accounts[0]);
      setNetwork(network);
      setChainId(network.chainId);
      setCorrectChain(network.chainId === contractChainID);

      //check if freemint
      let isFreemint = false
      let uri = `${backendURI}/${accounts[0]}/freemint/${REACT_APP_API_SECRET}`
      let response = await fetch(uri);
      let jsonReply = await response.json();
      // if(debug){
      //   console.log("No Freemint")
      // }
      if(jsonReply != "NotWhitelisted"){ // has a freemint
        if(debug){
          console.log("hasFreemint")
        }
        const responseArray = jsonReply.split(',');
        voucher = responseArray[0];
        accountListing = responseArray[1];

        const didReach = await reachedMaxMints(provider, accounts[0], true); // check if used
        setMintCountState(didReach ? 0 : 1);
        if(!didReach){ // free mint not used
          if(debug){
            console.log("freemint not used")
          }
          setWhiteListStat(1)
          isFreemint = true
        } else {
          if(debug){
            console.log("Freemint used")
          }
        }
      }

      if(!isFreemint){ // not freemint or freemint used
        const res = await CheckIfPresale(provider, accounts[0]);
        setIsPresale(res ? 1 : 0);
        if(res){
          uri = `${backendURI}/${accounts[0]}/presales/${REACT_APP_API_SECRET}`
          response = await fetch(uri);
          jsonReply = await response.json();
          if(jsonReply != "NotWhitelisted"){
            const responseArray = jsonReply.split(',');
            voucher = responseArray[0];
            accountListing = responseArray[1];
            setWhiteListStat(1)
          } else {
            accountListing = "presale";
            setWhiteListStat(0);
          }
        } else {
          accountListing = "public"
        }

        const didReach = await reachedMaxMints(provider, accounts[0], false);
        setMintCountState(didReach ? 0 : 1);
      }

      //
      if(debug){
        console.log(`accountListing ${accountListing}`);
      }
      // if (accountListing === "freemint") {
      //   const didReach = await reachedMaxMints(provider, accounts[0], true);
      //   setMintCountState(didReach ? 0 : 1);
      // } else {
      //   const didReach = await reachedMaxMints(provider, accounts[0], false);
      //   setMintCountState(didReach ? 0 : 1);
      // }
      const pauseCheck = await checkContractPaused(provider, accounts[0]);
      const sold = await checkSupply(provider, accounts[0]);
      const freeMintSupply = await checkFreemintSupply(provider, accounts[0]);

      const hasEnoughfunds = await checkFunds(provider, accounts[0]);
      if(debug){
        console.log(`hasEnoughfunds ${hasEnoughfunds}`)
      }
      sethasFunds(hasEnoughfunds ? 1 :0);

      setIsPaused(pauseCheck ? 1 : 0);
      setIsSoldOut(sold ? 1 : 0);
      setCanFreemint(freeMintSupply ? 1 : 0);
    } catch (error) {
      console.error(error);
    }
  };

  let loading = (
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-8 text-center mx-auto loadingContainer">
          <ReactLoading type={"spin"} color={"green"} height={50} width={50} />
        </div>
      </div>
    </div>
  );

  let TheButton = (
    <button
      style={{
        color: "black",
      }}
      className="rainbowBackground buttonTextCenter"
      onClick={connectWallet}
    >
      <span className="mintText">CONNECT</span>
    </button>
  );

  if (isMinting) {
    TheButton = loading;
  } else if (provider) {
    if (network) {
      if (isCorrectChain) {
        if (isSoldOut === 0) {
          if (isPaused === 0) {
            if (MintCountState === 1) {
              if (accountListing === "freemint") {
                if(hasFunds) {
                  if(canFreemint) {
                    TheButton = (
                      <button
                        className="rainbowBackground buttonTextCenter"
                        style={{
                          color: "black",
                        }}
                        onClick={() => {
                          Mint(provider, account, voucher);
                        }}
                      >
                        <span className="mintText p-0" style={{ width: "100%" }}>
                          Free MINT
                        </span>
                      </button>
                    );
                  } else {
                    TheButton = <p>Free Mint Supply Depleted !</p>;
                  }
                } else { 
                  TheButton = <p>Insufficient funds!</p>;
                }
              } else {
                if (isPresale === 1) {
                  if (WhitelistStat === 1) {
                    if(hasFunds) {
                      if (accountListing === "vip") {
                        TheButton = (
                          <button
                            className="rainbowBackground buttonTextCenter"
                            style={{
                              color: "black",
                            }}
                            onClick={() => {
                              Mint(provider, account, voucher);
                            }}
                          >
                            <span
                              className="mintText p-0"
                              style={{ width: "100%" }}
                            >
                              VIP Mint
                            </span>
                          </button>
                        );
                      } else {
                        TheButton = (
                          <button
                            className="rainbowBackground buttonTextCenter"
                            style={{
                              color: "black",
                            }}
                            onClick={() => {
                              Mint(provider, account, voucher);
                            }}
                          >
                            <span
                              className="mintText p-0"
                              style={{ width: "100%" }}
                            >
                              WL Mint
                            </span>
                          </button>
                        );
                      }
                    } else { 
                      TheButton = <p>Insufficient funds!</p>;
                    }
                  } else {
                    TheButton = <p>Sorry, you are not Whitelisted!</p>;
                  }
                } else if (isPresale === 0) {
                  if(hasFunds) {
                    TheButton = (
                      <button
                        className="rainbowBackground buttonTextCenter"
                        style={{
                          color: "black",
                        }}
                        onClick={() => {
                          Mint(provider, account, voucher);
                        }}
                      >
                        <span className="mintText">MINT</span>
                      </button>
                    );
                  } else { 
                    TheButton = <p>Insufficient funds!</p>;
                  }
                }
              }
            } else if (MintCountState === 0) {
              if(accountListing === "freemint"){
                TheButton = <p>Freemint Limit Reached!</p>;
              } else if(accountListing === "vip") {
                TheButton = <p>VIP Limit Reached!</p>;
              } else {
                TheButton = <p>Wallet Limit Reached!</p>;
              }
            } else if (MintCountState === null) {
              TheButton = loading;
            }
          } else if (isPaused === 1) {
            if(wlVerify === 0) {
              TheButton = <p>Minting is not allowed</p>;
            } else if (WhitelistStat === 1){
              if (accountListing === "freemint") {
                TheButton = <p>🎉Congratulations you get a free mint !🎉</p>;
              } else if (accountListing === "vip") {
                TheButton = <p>🎉Congratulations you are VIP whitelisted🎉</p>;
              } else{
                TheButton = <p>🎉Congratulations you are whitelisted🎉</p>;
              }
            } else {
              TheButton = <p>Sorry, you are not Whitelisted!</p>;
            }
          } else if (isPaused === null) {
            TheButton = loading;
          }
        } else if (isSoldOut === 1) {
          TheButton = <p>We are Sold Out!</p>;
        } else if (isSoldOut === null) {
          TheButton = loading;
        }
      } else {
        TheButton = (
          <button
            className="rainbowBackground buttonTextCenter"
            style={{
              color: "black",
            }}
            onClick={switchNetwork}
          >
            <span className="mintText">Switch Network</span>
          </button>
        );
      }
    }
  }
  let [showModal, setShowModal] = useState(false);

  return (
    <div className="container-fluid">
      <div class="row">
        <div class="col-6 mx-auto">
          <p>{totalSupply} / {maxSupply}</p>
          {TheButton}{" "}
          <ReactModal
            className="Congrats-modal"
            isOpen={showModal}
            contentLabel="Minimal Modal Example"
          >
            <div class="container">
              <div class="row" style={{ position: "relative" }}>
                <div
                  class="col-lg-6 col-11 mx-auto bg-dark p-5 rounded"
                  style={{
                    margin: 0,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,50%)",
                  }}
                >
                  <div class="row">
                    <div class="col mx-auto text-center">
                      <h1 className="my-3">🎉Congratulations!🎉</h1>
                      <h4 className="my-5">Thank you for Minting</h4>
                      <button
                        className="my-4 btn btn-light"
                        onClick={handleCloseModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>
        </div>
      </div>
    </div>
  );

  function handleOpenModal() {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
    refreshMintCount();
  }

  async function refreshMintCount() {
    if(debug){
      console.log("Refreshing status")
    }
    //
    getTotalSupply();
    setMintCountState(null);

    let isFreemint = false
    let uri = `${backendURI}/${account}/freemint/${REACT_APP_API_SECRET}`
    let response = await fetch(uri);
    let jsonReply = await response.json();
    if(debug){
      console.log("No Freemint")
    }
    if(jsonReply != "NotWhitelisted"){ // has a freemint
      if(debug){
        console.log("hasFreemint")
      }
      const responseArray = jsonReply.split(',');
      voucher = responseArray[0];
      accountListing = responseArray[1];

      const didReach = await reachedMaxMints(provider, account, true); // check if used
      setMintCountState(didReach ? 0 : 1);
      if(!didReach){ // free mint not used
        if(debug){
          console.log("freemint not used")
        }
        setWhiteListStat(1)
        isFreemint = true
      } else {
        if(debug){
          console.log("Freemint used")
        }
      }
    }

    if(!isFreemint){ 
      if(isPresale){
        const uri = `${backendURI}/${account}/presales/${REACT_APP_API_SECRET}`
        const response = await fetch(uri);
        const jsonReply = await response.json();
        if(jsonReply != "NotWhitelisted"){
          const responseArray = jsonReply.split(',');
          voucher = responseArray[0];
          accountListing = responseArray[1];
          setMintCountState(null);
          if(accountListing === "freemint") { 
            const didReach = await reachedMaxMints(provider, account, true);
            setMintCountState(didReach ? 0 : 1);
          } else {
            const didReach = await reachedMaxMints(provider, account, false);
            setMintCountState(didReach ? 0 : 1);
          }
          setWhiteListStat(1)
        } else {
          setMintCountState(null);
          const didReach = await reachedMaxMints(provider, account, false);
          setMintCountState(didReach ? 0 : 1);
          setWhiteListStat(0);
          accountListing = "presale";
          voucher = null;
        }
      } else {
        accountListing = "public"
        setMintCountState(null);
        const didReach = await reachedMaxMints(provider, account, false);
        setMintCountState(didReach ? 0 : 1);
      }
    }

    const hasEnoughfunds = await checkFunds(provider, account);
    if(debug){
      console.log(`hasEnoughfunds ${hasEnoughfunds}`)
    }
    sethasFunds(hasEnoughfunds ? 1 :0);
  }

  async function Mint(provider, address, voucher) {
    const library = new ethers.providers.Web3Provider(provider);
    const network = await library.getNetwork();

    if (network.chainId !== contractChainID) {
      window.location.reload(false);
      return;
    }

    let web3 = new Web3(provider);
    const contract = new web3.eth.Contract(ABI, CONT_ADD, {
      from: address,
    });

    setIsMinting(true);
    const getTokenPrice = async () => {
      const price = await contract.methods.getTokenPrice(accountListing).call();
      if(debug){
        console.log("price is " + price);
      }
      return web3.utils.fromWei(price, "ether");
    };

    try {
      let resp2 = null;
      if (accountListing === "freemint") {
        resp2 = await contract.methods.freeMint(voucher).send({
          from: address,
          value: "0",
        });
      } else if (isPresale) {
        resp2 = await contract.methods.whitelistMinting(voucher, accountListing).send({
          from: address,
          value: web3.utils.toWei(
            (parseFloat(await getTokenPrice())).toString()
          ),
        });
      } else {
        resp2 = await contract.methods.publicMint().send({
          from: address,
          value: web3.utils.toWei(
            (parseFloat(await getTokenPrice())).toString()
          ),
        });
      }
      setIsMinting(false);
      if (resp2.status === true) {
        handleOpenModal();
      }
      if(debug){
        console.log(resp2.status);
      }
    } catch (err) {
      setIsMinting(false);
      if(debug){
        console.log(err);
      }
    }
  }

  async function reachedMaxMints(provider, address, asFreemint) {
    const library = new ethers.providers.Web3Provider(provider);
    const network = await library.getNetwork();
    if (network.chainId !== contractChainID) {
      window.location.reload(false);
      return;
    }
    let web3 = new Web3(provider);
    const contract = new web3.eth.Contract(ABI, CONT_ADD, {
      from: address,
    });
    const mintedTokens = Number(await contract.methods
      .getNbrOfMintedToken(address, asFreemint)
      .call());
    if(debug){
      console.log(`minted ${mintedTokens}`)
    }
    const maxMints = Number(await contract.methods.getWalletLimit(accountListing).call());

    if(debug){
      console.log(`minted ${mintedTokens} maxMints ${maxMints}`)
      console.log( mintedTokens >= maxMints )
    }

    return mintedTokens >= maxMints;
  }

  async function CheckIfPresale(provider, address) {
    const library = new ethers.providers.Web3Provider(provider);
    const network = await library.getNetwork();
    if (network.chainId !== contractChainID) {
      window.location.reload(false);
      return;
    }
    let web3 = new Web3(provider);
    const contract = new web3.eth.Contract(ABI, CONT_ADD, {
      from: address,
    });

    const isPresale = await contract.methods.presale().call();
    if(debug){
      console.log(`isPresale ${isPresale}`);
    }
    return isPresale;
    // return true;
  }
//
  async function checkFunds(provider, address) {
    const library = new ethers.providers.Web3Provider(provider);
    const network = await library.getNetwork();
    if (network.chainId !== contractChainID) {
      window.location.reload(false);
      return;
    }
    let web3 = new Web3(provider);
    const contract = new web3.eth.Contract(ABI, CONT_ADD, {
      from: address,
    });

    const getWeiTokenPrice = async () => {
      const price = await contract.methods.getTokenPrice(accountListing).call();
      if(debug){
        console.log("price in wei is " + price);
      }
      return price;
    };


    const balance = await web3.eth.getBalance(address);
    const weiPrice = await getWeiTokenPrice();
    // const balance = await provider.getBalance(address);
    if(debug){
      console.log(`balance ${balance.toString()}`);
      console.log(parseInt(balance.toString()) >= parseInt(weiPrice));
    }
    return parseInt(balance.toString()) >= parseInt(weiPrice);
  }
//
  async function checkContractPaused(provider, address) {
    const library = new ethers.providers.Web3Provider(provider);
    const network = await library.getNetwork();
    if (network.chainId !== contractChainID) {
      window.location.reload(false);
      return;
    }
    let web3 = new Web3(provider);
    const contract = new web3.eth.Contract(ABI, CONT_ADD, {
      from: address,
    });
    const res = await contract.methods.paused().call();

    return res;
  }

  async function checkSupply(provider, address) {
    const library = new ethers.providers.Web3Provider(provider);
    const network = await library.getNetwork();
    if (network.chainId !== contractChainID) {
      window.location.reload(false);
      return;
    }
    let web3 = new Web3(provider);
    const contract = new web3.eth.Contract(ABI, CONT_ADD, {
      from: address,
    });
    const totalSply = parseInt(await contract.methods.totalSupply(1).call());
    const maxSply = parseInt(await contract.methods.maxSupply().call());

    return totalSply >= maxSply;
  }

  async function getMaxSupply() {
    if(debug){
      console.log(`in maxSupply`)
    }
    // let web3 = new Web3(Web3.givenProvider);
    let web3 = new Web3(infuraID);
    const contract = new web3.eth.Contract(ABI, CONT_ADD);
    const maxSply = parseInt(await contract.methods.maxSupply().call());
    if(debug){
      console.log(`maxSupply ${maxSply}`);
    }
    setMaxSupply(maxSply);
  }

  async function getTotalSupply() {
    if(debug){
      console.log(`in getTotalSupply`)
    }
    // let web3 = new Web3(Web3.givenProvider);
    let web3 = new Web3(infuraID);
    const contract = new web3.eth.Contract(ABI, CONT_ADD);

    const totalsSply = parseInt(await contract.methods.totalSupply(1).call());

    if(debug){
      console.log(`TotalSupply ${totalsSply}`);
    }
    setTotalSupply(totalsSply);
  }

  async function checkFreemintSupply(provider, address) {
    const library = new ethers.providers.Web3Provider(provider);
    const network = await library.getNetwork();
    if (network.chainId !== contractChainID) {
      window.location.reload(false);
      return;
    }
    let web3 = new Web3(provider);
    const contract = new web3.eth.Contract(ABI, CONT_ADD, {
      from: address,
    });
    const freeMintSupply = parseInt(await contract.methods.freeMintSupply().call());

    if(debug){
      console.log(`freeMintSupply ${freeMintSupply}`);
    }
    
    return freeMintSupply > 0;
  }
}
